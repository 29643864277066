import axios from "../configs/axios";
import moment from "moment";
import { connect } from "react-redux";
import { logout } from "../redux/actions/auth/loginActions";
import { store } from "../redux/storeConfig/store";

export const spacesBetween = (txt) => {
  return (
    txt
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
  );
};

export const strBetweenDoubleQuots = (msg) =>
  msg.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// export const toTitleCase = phrase => {
//   return phrase !== undefined && _.startCase(_.toLower(phrase));
// };

export const pause = (duration) => {
  const start = Date.now();
  while (Date.now() - start < duration) {}
};

export const sleep = (duration) => pause(duration);

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const emailValidate = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim());
};

export const passwordValidate = (pwd) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  const isValid = new RegExp(regex).test(pwd.trim());

  return isValid;
};

export const validate = (key = "email", val) => {
  if (key.toLowerCase() === "email") {
    return emailValidate(val);
  } else if (key.toLowerCase() === "password") {
    return passwordValidate(val);
  }
};

export const phoneValidate = (number) => {
  console.log({ number });
  number = number.replace("-", "");
  number = number.replace("+", "");
  number = number.split("");
  if (number[0] + number[1] === "92") {
    number[0] = NaN;
    number[1] = 0;
    number = number.join("").split("NaN")[1];
  } else {
    number = number.join("");
  }

  if (number.length === 11) return true;
  return false;
};

export const convertPhone = (number) => {
  number = number.replace("-", "");
  number = number.replace("+", "");
  number = number.split("");
  if (number[0] + number[1] === "92") {
    number[0] = "";
    number[1] = 0;
    number = number.join("");
  } else {
    number = number.join("");
  }
  if (number.length === 11) return number;
  return false;
};

export const validateLength = (val, len = 3) => val.length >= len;

export const getAccessToken = async (refreshToken) => {
  if (!refreshToken) {
    return false;
  }

  // await store.dispatch(updateAccessTokenAction(refreshToken));
  // return store.getState().login.accessToken;
};

/**
 * method =   is type of action i.e get, post put patch delete
 * endPoint = is the url of the end point
 * data =     is data to send with the request
 * token =    access token to get access to that end point
 * headers =  extra headers if provided
 */
export const apiCall = (method, endPoint, data, token, headers = {}) => {
  if (method.toLowerCase() === "post" || method.toLowerCase() === "patch") {
    const res = axios[method](endPoint, data, {
      headers: authHeader(token, headers),
    });

    res
      .then((result) => result)
      .catch((err) => {
        if (err.response && err.response.data.message === "Unauthenticated") {
          store.dispatch(logout());
        }
      });
    return res;
  }
  const response = axios[method](endPoint, {
    headers: authHeader(token, headers),
  });

  response
    .then((result) => result)
    .catch((err) => {
      if (err.response && err.response.data.message === "Unauthenticated") {
        store.dispatch(logout());
      }
    });
  return response;
};

export const authHeader = (token, rest) => {
  return { ...rest, authorization: `Bearer ${token}` };
};

export default connect(null, logout)(apiCall);

export const Capitalize = (str) => {
  if (!str) {
    return;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const autoCompleteValue = (e) => {
  return e.currentTarget.innerText;
};

export const formatTime = (time) => {
  return moment(time).format("HH:mm a");
};
export const sendDateFormat = (date) => {
  return moment(date, "YYYY-MM-DD", true).isValid()
    ? moment(date, "YYYY-MM-DD").format("YYYY-MM-DD")
    : moment(date, "MMM DD YYYY").format("YYYY-MM-DD");
};

export const filterUrl = (filterArray) => {
  let url = "";
  filterArray.forEach((item) => {
    if (item.type === "select") {
      if (item.value.value) {
        url = url.concat(`${item.name}=${item.value.value}&`);
      }
    } else if (item.value) {
      url = url.concat(`${item.name}=${item.value}&`);
    }
  });
  return url;
};

export const hourSlots = () => {
  const slots = [];
  for (let index = 1; index < 25; index++) {
    slots.push({
      value: `${moment(moment("00").add(index, "hours"), "HH").format(
        "HH"
      )}:00`,
      label: moment(moment("00").add(index, "hours"), "HH").format("HH A"),
    });
  }
  return slots;
};

export const searchDays = (start, end) => {
  //let weekdayCounter = 0;
  let array = {
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  };

  while (start <= end) {
    array = { ...array, [start.format("ddd")]: true };

    start = moment(start, "YYYY-MM-DD").add(1, "days"); //increment by one day
  }
  return array;
};

export const aptStatus = (status) => {
  if (status === "pending") {
    return "Pending";
  } else if (status === "approved") {
    return "Approved";
  } else if (status === "cancel_by_doctor") {
    return "Cancelled by Doctor";
  } else if (status === "cancel_by_patient") {
    return "Cancelled by Patient";
  } else if (status === "cancel_by_patient") {
    return "Cancelled by Patient";
  } else if (status === "no_show") {
    return "No Show";
  } else if (status === "done") {
    return "Done";
  } else if (status === "ongoing") {
    return "On Going";
  } else if (status === "follow_up") {
    return "Follow Up";
  } else if (status === "reschedual") {
    return "Reschedual";
  } else if (status === "refund") {
    return "Refund";
  } else if (status === "auto_cancel") {
    return "Auto Cancel";
  } else if (status === "awaiting_confirmation") {
    return "Awaiting Confirmation";
  }
};

export function pdfBlobConversion(b64Data, contentType) {
  contentType = contentType || "";
  var sliceSize = 512;
  b64Data = b64Data.replace(/^[^,]+,/, "");
  b64Data = b64Data.replace(/\s/g, "");
  var byteCharacters = window.atob(b64Data);
  var byteArrays = [];

  for (
    var offset = 0;
    offset < byteCharacters.length;
    offset = offset + sliceSize
  ) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  console.log("blob============", blob);
  return blob;
}
