export const login = (state = { videoData: false }, action) => {
  switch (action.type) {
    case "LOGIN": {
      return { ...state, userData: action.payload };
    }
    case "LOGIN-PATIENT": {
      return { ...state, userData: action.payload };
    }
    case "LOGOUT": {
      return { ...state, userData: null };
    }
    case "REDIRECT": {
      return { ...state, redirect: action.payload };
    }
    case "VIDEOTOKEN": {
      return { ...state, videoData: action.payload };
    }
    case "UPDATE_PROFILE_PIC": {
      return {
        ...state,
        userData: {
          ...state.userData,
          profile: { ...state.userData.profile, image: action.payload },
        },
      };
    }

    case "UPDATE_NAME": {
      return {
        ...state,
        userData: {
          ...state.userData,
          profile: { ...state.userData.profile, name: action.payload },
        },
      };
    }

    default: {
      return state;
    }
  }
};
