import { history } from "../../../history";
import axios from "axios";
import { BASE_URL_API } from "../../../configs/constants";
import { getCountries } from "../countries";
import { store } from "../../storeConfig/store";

export const login = (loginCrediantials) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${BASE_URL_API}login`,
      loginCrediantials
    );
    getCountries();
    dispatch({ type: "LOGIN", payload: response.data.data });
    if (store.getState().auth.login.redirect) {
      history.push(store.getState().auth.login.redirect);
    } else {
      history.push("/");
    }
  } catch (err) {
    if (err.response) {
      return err.response.data.message;
    }
    return "Something went wrong!";
  }
};

export const videoToken = (data) => async (dispatch) => {
  dispatch({ type: "VIDEOTOKEN", payload: data });
};

export const redirect = (path) => async (dispatch) => {
  dispatch({ type: "REDIRECT", payload: path });
};

export const updateProfilePic = (pic) => async (dispatch) => {
  dispatch({ type: "UPDATE_PROFILE_PIC", payload: pic });
};

export const patientLogin = (loginCrediantials) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${BASE_URL_API}login/${loginCrediantials.docId}`,
      loginCrediantials
    );
    dispatch({ type: "LOGIN-PATIENT", payload: response.data.data });
    if (response.data.data.url) {
      history.push(`/${response.data.data.url}`);
    } else if (store.getState().auth.login.redirect) {
      history.push(store.getState().auth.login.redirect);
    } else {
      history.push("/");
    }
  } catch (err) {
    if (err.response) {
      return err.response.data.message;
    }
    return "Something went wrong!";
  }
};

export const logout = (token, actor) => async (dispatch) => {
  let check = false;
  try {
    if (store.getState().auth.login.userData.actor === "PATIENT") {
      check = store.getState().auth.login.userData.profile.doctor.url;
    }
    const response = await axios.post(`${BASE_URL_API}logout`, undefined, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    dispatch({ type: "LOGOUT", payload: response.data.data });
    dispatch({ type: "REDIRECT", payload: null });
    if (check) {
      history.push(`/doctor/${check}`);
    } else {
      history.push("/login");
    }

    return response.data.message;
  } catch (err) {
    if (err.response && err.response.data.message === "Unauthenticated")
      dispatch({ type: "LOGOUT" });
    if (check) {
      history.push(`/doctor/${check}`);
    } else {
      history.push("/login");
    }
    if (err.response) {
      return err.response.data.message;
    }
  }
};

export const updateName = (name) => async (dispatch) => {
  dispatch({ type: "UPDATE_NAME", payload: name });
};
