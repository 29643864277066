const initialState = {
  chats: {},
  contacts: [],
  chatContacts: [],
  pinned: [],
  status: "active",
  filteredContacts: [],
  filteredChats: [],
};

const chats = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACTS":
      return { ...state, contacts: action.contacts };
    case "GET_CHATS":
      return { ...state, chatContacts: action.chats };
    case "GET_CHAT_CONTACTS":
      return { ...state, chatContacts: action.chats };
    case "INIT_CHAT":
      return { ...state, token: action.token };
    case "GET_CHAT":
      let chat;
      if (state.chats[action.data.uid]) {
        chat = {
          ...state.chats,
          [action.data.uid]: {
            isPinned: action.isPinned,
            allDone: action.allDone,
            msg: [...action.data.chat, ...state.chats[action.data.uid].msg],
          },
        };
      } else {
        chat = {
          ...state.chats,
          [action.data.uid]: {
            isPinned: action.isPinned,
            msg: [...action.data.chat],
          },
        };
      }
      return { ...state, chats: chat };
    case "SEND_MESSAGE":
      let sendMsg;
      if (state.chats[action.id]) {
        let oldState = state.chats[action.id].msg;
        sendMsg = {
          ...state.chats,
          [action.id]: {
            isPinned: action.isPinned,
            msg: [...oldState, action.msg],
          },
        };
      } else {
        sendMsg = {
          ...state.chats,
          [action.id]: {
            isPinned: action.isPinned,
            msg: [action.msg],
          },
        };
      }
      // if (!oldChatContactsId.includes(action.id)) {
      //   let extractContactInfo = state.contacts.find(
      //     (k) => k.uid === action.id
      //   );
      //   newChatContacts = state.chatContacts.concat(extractContactInfo);
      // } else {
      //   newChatContacts = state.chatContacts;
      // }
      return { ...state, chats: sendMsg };
    case "CHANGE_STATUS":
      return { ...state, status: action.status };
    case "MARK_AS_SEEN":
      let marked = state.chats[action.id];
      marked !== undefined &&
        marked.msg.forEach((msg) => {
          msg.isSeen = true;
        });
      return { ...state };
    case "SEARCH_CONTACTS":
      if (action.query.length) {
        let filteredContacts = state.contacts.filter((contact) => {
          //console.log("contacts::::", contact);
          return contact
            ? contact.name.toLowerCase().includes(action.query.toLowerCase())
            : false;
        });
        let filteredChats = state.chatContacts.filter((chat) => {
          //console.log("chat::::", chat);
          return chat.patient_user
            ? chat.patient_user.name
                .toLowerCase()
                .includes(action.query.toLowerCase())
            : false;
        });
        return { ...state, filteredContacts, filteredChats };
      } else {
        return { ...state };
      }
    case "SET_PINNED":
      let pinned = state.chats[action.id];
      if (pinned) {
        pinned.isPinned = action.value;
        state.chatContacts.sort((a, b) => b.uid - a.uid);
        return { ...state };
      } else {
        return { ...state };
      }
    default:
      return { ...state };
  }
};

export default chats;
